import {EstateType, OfferType, Currency} from '.cache/__types__';
import {Filter, LocationEnum} from '@utils/query';
import {Feature, Geometry} from 'geojson';
import {AutocompleteProperties} from '@liveComponents/Autocomplete/autocompleteHelper';

export type State = {
    showEstateTypeDropdown: boolean;
    showOfferTypeDropdown: boolean;
    showPriceDropdown: boolean;
    showSearchSidebar: boolean;

    filter: Required<
        Pick<
            Filter,
            | 'offerType'
            | 'estateType'
            | 'priceFrom'
            | 'priceTo'
            | 'polygonBuffer'
            | 'osm_value'
            | 'regionOsmIds'
            | 'currency'
            | 'searchPriceWithCharges'
        >
    > & {
        location?: LocationEnum;
    };

    step: StepsEnum;
};

export enum StepsEnum {
    Offer = 'offer',
    Estate = 'estate',
    Locality = 'locality',
    Price = 'price',
}

export type Action =
    | ToggleAction
    | SetToggle
    | Reset
    | SetRadioFilter
    | SetIntFilter
    | SetFilter
    | SetFeature
    | SetCurrency
    | SetCheckboxFilter;

export enum ActionTypes {
    toggle,
    setToggle,
    reset,
    setRadioFilter,
    setCheckboxFilter,
    setIntFilter,
    setFilter,
    setRange,
    setFeature,
    setCurrency,
}

export enum RadioFilterEnum {
    Estate = 'estateType',
    Offer = 'offerType',
}

export type RadioFilterAction = SetRadioOffer | SetRadioEstate;

type SetRadioOffer = {
    attr: RadioFilterEnum.Offer;
    value: OfferType;
};

type SetRadioEstate = {
    attr: RadioFilterEnum.Estate;
    value: EstateType;
};

type SetRadioFilter = {
    type: ActionTypes.setRadioFilter;
    value: RadioFilterAction;
};

type SetFilter = {
    type: ActionTypes.setFilter;
    filter: State['filter'];
};

export enum Toggles {
    EstateType = 'showEstateTypeDropdown',
    OfferType = 'showOfferTypeDropdown',
    Price = 'showPriceDropdown',
    SearchSidebar = 'showSearchSidebar',
}

type ToggleAction = {
    type: ActionTypes.toggle;
    attr: Toggles;
};

type SetToggle = {
    type: ActionTypes.setToggle;
    attr: Toggles;
    value: boolean;
};

export enum IntFilterEnum {
    PriceFrom = 'priceFrom',
    PriceTo = 'priceTo',
    PolygonBuffer = 'polygonBuffer',
}

type SetIntFilter = {
    type: ActionTypes.setIntFilter;
    attr: IntFilterEnum;
    value: number | null;
};

type Reset = {
    type: ActionTypes.reset;
};

type SetFeature = {
    type: ActionTypes.setFeature;
    feature: Feature<Geometry, AutocompleteProperties>;
};

type SetCurrency = {
    type: ActionTypes.setCurrency;
    value: Currency | null;
};

export enum CheckBoxEnum {
    SearchPriceWithCharges = 'searchPriceWithCharges',
}

type SetCheckboxFilter = {
    type: ActionTypes.setCheckboxFilter;
    attr: CheckBoxEnum;
};
