import classNames from 'classnames';
import React, { FC } from 'react';
import { Element } from 'react-scroll';
import styles from './Anchor.module.scss';

type AnchorProps = {
	id: string;
	fullSection?: boolean;
	className?: string;
}

export const Anchor: FC<React.PropsWithChildren<AnchorProps>> = ({ id, fullSection, className, children }) => {
	return (
		<Element
			name={id}
			id={id}
			className={classNames(styles.anchor, 'anchor', className, {
				[styles['anchor--full-section']]: fullSection,
			})}
		>
			{children}
		</Element>
	);
};
