import {PropsWithChildren, forwardRef} from 'react';
import {getSharedManager} from 'react-bootstrap/BootstrapModalManager';
import ModalComponent, {ModalProps as ModalComponentProps} from 'react-bootstrap/Modal';
import {useIsRTL} from 'react-bootstrap/cjs/ThemeProvider';
import {BsPrefixRefForwardingComponent} from 'react-bootstrap/esm/helpers';
import {ModalHeader} from './ModalHeader';

const getBodyScrollbarWidth = (ownerDocument = document) => {
    const window = ownerDocument.defaultView;

    if (!window) {
        return 0;
    }

    const bodyHasOverflowHidden = ownerDocument.body.style.overflow === 'hidden';

    return bodyHasOverflowHidden
        ? parseInt(ownerDocument.body.style.paddingRight, 10)
        : Math.abs(window.innerWidth - ownerDocument.documentElement.clientWidth);
};

// @ts-expect-error
const ModalWithManager: BsPrefixRefForwardingComponent<'div', ModalComponentProps> = forwardRef<
    HTMLDivElement,
    PropsWithChildren<ModalComponentProps>
>(({...props}, ref) => {
    const isRTL = useIsRTL();

    const manager = Object.assign(
        getSharedManager({
            isRTL,
        }),
        {
            getScrollbarWidth: () => {
                return getBodyScrollbarWidth();
            },
        },
    );

    return <ModalComponent ref={ref} {...props} manager={manager} />;
});

type ModalProps = typeof ModalComponent & ModalComponentProps;

const Modal = {
    ...ModalComponent,
    ...ModalWithManager,
};

export default Object.assign(Modal as ModalProps, {
    Header: ModalHeader,
});
