import {Dispatch} from 'react';
import {State, ActionTypes, Action, Toggles, RadioFilterEnum, RadioFilterAction, IntFilterEnum, StepsEnum, CheckBoxEnum} from './types';
import {listDefaultType as estateDefault} from '@constants/EstateType';
import {listDefaultType as offerDefault} from '@constants/OfferType';
import {Filter, LocationEnum, defaultPage} from '@utils/query';
import {AutocompleteProperties, NominatimOsmId} from '@liveComponents/Autocomplete/autocompleteHelper';
import {Feature, Geometry} from 'geojson';
import {Currency} from '.cache/__types__';
import {LocaleEnum, LocaleType} from 'src/types/general';
import {defaultCurrencyByLocale} from '@constants/Currency';
import {CountryEnum} from '@constants/Country';

export const initialState: State = {
    showEstateTypeDropdown: false,
    showOfferTypeDropdown: false,
    showPriceDropdown: false,
    showSearchSidebar: false,

    filter: {
        offerType: [offerDefault],
        estateType: [estateDefault],
        priceFrom: null,
        priceTo: null,
        osm_value: '',
        regionOsmIds: [],
        currency: null,
        searchPriceWithCharges: false,
        polygonBuffer: 0,
    },
    step: StepsEnum.Offer,
};

export const getInitialState =
    (lastFilter: Filter, locale: LocaleType) =>
    (initialState: State): State => {
        const Osm: {country: CountryEnum; regionOsmIds: NominatimOsmId[]; osm_value: string} =
            locale === LocaleEnum.sk
                ? {country: CountryEnum.SK, regionOsmIds: ['R14296'], osm_value: 'Slovensko'}
                : {country: CountryEnum.CR, regionOsmIds: ['R51684'], osm_value: 'Česko'};

        return {
            ...initialState,
            filter: {
                ...initialState.filter,
                currency: lastFilter.currency ?? defaultCurrencyByLocale[locale],
                ...Osm,
            },
        };
    };

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.toggle: {
            return {
                ...state,
                [action.attr]: !state[action.attr],
            };
        }

        case ActionTypes.setToggle:
            return {
                ...state,
                [action.attr]: action.value,
            };

        case ActionTypes.setIntFilter: {
            const filter = state.filter;

            filter[action.attr] = action.value;

            return {...state, filter};
        }

        case ActionTypes.reset: {
            const filter = JSON.parse(JSON.stringify(initialState.filter));
            return {
                ...initialState,
                filter,
            };
        }

        case ActionTypes.setFilter: {
            return {
                ...state,
                filter: action.filter,
            };
        }

        case ActionTypes.setFeature: {
            const filter = JSON.parse(JSON.stringify(state.filter)) as State['filter'];

            filter.osm_value = action.feature.properties.label;
            filter.regionOsmIds = [getRegionOsmId(action.feature.properties.osm_type, action.feature.properties.osm_id)];
            filter.location = LocationEnum.Exact;

            return {
                ...state,
                filter,
                step: StepsEnum.Price,
            };
        }

        case ActionTypes.setRadioFilter: {
            const filter = JSON.parse(JSON.stringify(state.filter));
            filter.page = defaultPage;

            switch (action.value.attr) {
                case RadioFilterEnum.Estate:
                case RadioFilterEnum.Offer:
                    filter[action.value.attr][0] = action.value.value;
                    const step = action.value.attr === RadioFilterEnum.Offer ? StepsEnum.Estate : StepsEnum.Locality;
                    return {...state, filter, step};

                default:
                    throw new Error('Undefined Radio type');
            }
        }

        case ActionTypes.setCurrency: {
            const filter = state.filter;
            filter.currency = action.value;

            return {...state, filter};
        }

        case ActionTypes.setCheckboxFilter: {
            const filter = JSON.parse(JSON.stringify(state.filter));
            filter[action.attr] = !filter[action.attr];
            return {...state, filter};
        }

        default:
            // or just 'return state;'
            throw new Error('Undefined dispatch type');
    }
};

export const setRadioFilter = (dispatch: Dispatch<Action>) => (value: RadioFilterAction) => () => {
    dispatch({type: ActionTypes.setRadioFilter, value});
};

export const toggleCheckBox = (dispatch: Dispatch<Action>) => (attr: CheckBoxEnum) => () => {
    dispatch({type: ActionTypes.setCheckboxFilter, attr});
};

export const setIntFilter = (dispatch: Dispatch<Action>) => (attr: IntFilterEnum) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null;
    dispatch({type: ActionTypes.setIntFilter, attr, value: intValue});
};

export const onClickSetToggle = (dispatch: Dispatch<Action>) => (attr: Toggles, value: boolean) => () => {
    dispatch({type: ActionTypes.setToggle, attr, value});
};

export const getRegionOsmId = (osm_type: string, osm_id: string): NominatimOsmId => {
    const osmType = osm_type[0].toLocaleUpperCase();
    switch (osmType) {
        case 'N':
        case 'R':
        case 'W':
            return `${osmType}${parseInt(osm_id)}`;

        default:
            throw new Error('Bad OSM type');
    }
};

export const onSelectFeature = (dispatch: Dispatch<Action>) => (feature: Feature<Geometry, AutocompleteProperties>) => {
    dispatch({type: ActionTypes.setFeature, feature});
};

export const setCurrencyFilter = (dispatch: Dispatch<Action>) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({type: ActionTypes.setCurrency, value: e.target.value as Currency});
};
