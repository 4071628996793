import classNames from 'classnames';
import React, { FC } from 'react';
import ModalHeaderComponent, { ModalHeaderProps as ModalHeaderComponentProps } from 'react-bootstrap/ModalHeader';

export type ModalHeaderProps = ModalHeaderComponentProps & {
	noBorder?: boolean;
};

export const ModalHeader: FC<React.PropsWithChildren<ModalHeaderProps>> = ({ noBorder, ...props }) => {
	return (
		<ModalHeaderComponent
			{...props}
			className={classNames({
				['modal-header--no-bd']: noBorder,
			})}
		/>
	);
};
