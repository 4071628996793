import classNames from 'classnames';
import React, {FC} from 'react';
import {Background} from '../../types/colors';
import {Anchor} from '../Anchor';
import {Box, BoxProps} from '../Box';
import styles from './Section.module.scss';

type SectionProps = BoxProps & {
    centerDrawnBackground?: boolean;
    background?: Background;
    bgTopHalf?: boolean;
    bgBottomHalf?: boolean;
    scrollId?: string;
};

export const Section: FC<React.PropsWithChildren<SectionProps>> = ({
    centerDrawnBackground,
    background,
    bgTopHalf,
    bgBottomHalf,
    scrollId,
    className,
    children,
    ...rest
}) => {
    return (
        <Box
            as="section"
            className={classNames(styles.section, 'section', className, {
                [styles[`section--cdbg`]]: centerDrawnBackground,
                [styles[`section--bgTopHalf`]]: bgTopHalf,
                [styles[`section--bgBottomHalf`]]: bgBottomHalf,
                [`bg-${background}`]: background,
                ['position-relative']: scrollId,
            })}
            {...rest}
        >
            {scrollId && <Anchor id={scrollId} fullSection />}
            {children}
        </Box>
    );
};
